/*
    Image Gallery Single
*/
$-primary-image-height: 590;
$-secondary-image-height: 480;
$-primary-image-width: 55%;
$-image-box-layer-height: 50;

.image-gallery-double {
    position: relative;
    
    display: flex;
    flex-direction: column;
    padding-right: 0;
    padding-left: 0;
    
    @media (--from-normal-screen-w) {
        height: rem(650);
        padding-top: rem(50);
        padding-bottom: rem(50);
    }


    // Elements
    &-inner {
        padding-right: rem($horizontal-spacing-small);
        padding-left: rem($horizontal-spacing-small);

        @media (--from-small-screen-w) {
            padding-right: rem($horizontal-spacing-medium);
            padding-left: rem($horizontal-spacing-medium);
        }

        @media (--from-normal-screen-w) {
            position: absolute;
            top: rem(170);
            
            flex-shrink: 0;
            width: rem(300);
        }
    }

    &-content {
        @media (--to-normal-screen-w) {
            margin-bottom: rem(30);
        }
    }

    &-pagination {
        padding-right: rem($horizontal-spacing-small);
        padding-left: rem($horizontal-spacing-small);
        margin-bottom: rem(40);       

        @media (--to-normal-screen-w) {
            order: 3;
        }

        @media (--from-small-screen-w) {
            padding-right: rem($horizontal-spacing-medium);
            padding-left: rem($horizontal-spacing-medium);
        }

        @media (--from-normal-screen-w) {
            position: absolute;
            top: rem(125);

            width: 100%;
            margin-bottom: 0;
        }
    }
    
    &-buttons {    
        @media (--to-normal-screen-w) {
            display: none;            
        }

        @media (--from-normal-screen-w) {
            position: absolute;
            top: rem($-secondary-image-height);
            left: $-primary-image-width;
            z-index: $layer-image-gallery-buttons;
        }
    }

    &-media {   
        @media (--from-normal-screen-w) {
            position: absolute;
            top: rem($-image-box-layer-height);
            left: rem(345);
            
            width: 60vw;
            max-width: rem(870);
        }


        // Pseudo
        &:before {
            @media (--from-normal-screen-w) {
                position: absolute;
                top: rem(-$-image-box-layer-height);
                right: 0;
                z-index: $layer-image-gallery-double-tile;

                width: 85%;
                height: rem(640);	
                
                background-color: $color-light-shade-1;
                content: '';
            }
        }


        // Types
        &.desktop {
            @media (--to-normal-screen-w) {    
                display: none;
            }
        }

        &.mobile {
            @media (--from-normal-screen-w) {    
                display: none;
            }
        }


        // Elements
        &-inner {
            position: relative;

            height: rem(370);

            outline: none;

            @media (--to-normal-screen-w) {
                padding: 0 rem(5);
                margin-bottom: rem(20);
            }

            @media (--from-small-screen-w) {
                height: rem(450);
            }

            @media (--from-normal-screen-w) {
                height: rem($-primary-image-height);
            }
        }

        .primary {
            width: 100%;
            height: 100%;

            @media (--from-normal-screen-w) {
                position: absolute;
                top: 0;
                left: 0;

                width: $-primary-image-width;
                height: rem($-primary-image-height);
            }
        }

        .secondary {
            @media (--from-normal-screen-w) {
                position: absolute;
                top: 0;
                right: 0;
    
                width: 45%;
                height: rem($-secondary-image-height);
            }
        }
    }

    .slick-slide.slick-active.slick-current .primary {
        @media (--from-normal-screen-w) {
            box-shadow: 0 rem(2) rem(4) 0 rgba(0, 0, 0, 0.31);
        }
    }
}
