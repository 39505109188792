/*
    Header
*/
.header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: rem($header-height);

    background-color: $color-light;
    border-bottom: rem(1) solid color($color-dark-tint-2 alpha(75%));

    @media (--from-navigation-breakpoint) {
        height: rem($header-height-large);
    }


    // Elements
    &-inner {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-right: 0; // IE compatibility
        margin-left: 0; // IE compatibility


        // Elements
        > * {
            flex-grow: 0;
        }
    }

    &-logo {
        display: flex;
        align-items: center;
        justify-content: center;

        @media (--from-navigation-breakpoint) {
            padding-bottom: rem(8);
        }


        // Elements
        .logo {
            width: rem(135);
            height: auto;

            @media (--from-navigation-breakpoint) {
                width: rem(190);
            }
        }
    }

    &-strut {
        flex-grow: 1;
    }

    &-menu-button {
        @media (--from-navigation-breakpoint) {
            display: none;
        }
    }

    &-close-button {
        height: rem(40);
        min-width: 0;

        font-size: rem(16);


        // Elements
        &-label {
            margin-right: rem(8);

            @media (--to-navigation-breakpoint) {
                display: none;
            }
        }
    }

    &-notification-toggle {
        position: relative;


        // States
        &.is-active {
            &:before {
                opacity: 0;
            }
        }


        // Pseudo
        &:before {
            position: absolute;
            top: rem(8);
            right: rem(10);

            display: block;
            width: rem(12);
            height: rem(12);

            background-color: $color-primary;
            border-radius: 50%;
            content: '';
            opacity: 1;

            transition: opacity 0.15s ease-in-out;
        }
    }
}
