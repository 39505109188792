/*
    Button
*/
.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: rem(52);
    min-width: rem(168);
    padding-right: rem(30);
    padding-left: rem(30);
    
    color: $color-primary;
    font-family: $font-primary;
    font-size: rem(16);
    font-weight: $weight-bold;
    line-height: 1.12;
    text-transform: uppercase;
    
    cursor: pointer;
    background-color: $color-light;
    border: rem(1) solid $color-dark;
    border-radius: rem(2);

    transition: border-color 0.25s ease-in-out, background-color 0.25s ease-in-out;
    

    // States
    &:hover {
        @media (--from-medium-screen-w) {
            border-color: $color-primary;
        }
    }

    &:active,
    &.is-active {
        border-color: $color-primary;
    }

    &:disabled,
    &.is-disabled {
        color: $color-disabled;
        
        pointer-events: none;
        cursor: not-allowed;
        
        border-color: $color-disabled;
    }


    // Themes
    &.alternative {
        color: $color-light;
        
        background-color: $color-dark;
        
        border-color: transparent;

        
        // States
        &:hover {
            @media (--from-medium-screen-w) {
                background-color: $color-primary;
            }
        }

        &:active,
        &.is-active {
            background-color: $color-primary;
        }

        &:disabled,
        &.is-disabled {
            color: $color-light;

            background-color: $color-disabled;
        }
    }
}