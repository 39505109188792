/*
    Tile
 */
.tile {
    padding-right: rem(5);
    padding-left: rem(5);

    @media (--from-smaller-screen-w) {
        height: 100%;


        // Elements
        a {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }

    @media (--from-medium-screen-w) {
        padding-right: rem(10);
        padding-left: rem(10);
    }


    // States
    &:hover {
        .tile-image {
            opacity: 0.5;
        }

        .tile-read-more {
            opacity: 1;
        }
    }


    // Elements
    &-image {
        height: rem(190);

        background-color: $color-light-shade-1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        transition: opacity 200ms ease-in-out;
    }

    &-description {
        padding-top: rem(18);
        padding-bottom: rem(18);
        margin-bottom: 0;

        font-size: rem(16);
        font-weight: $weight-bold;
        letter-spacing: 0.03em;
        
        border-bottom: rem(1) solid $color-dark-tint-2;

        @media (--from-smaller-screen-w) {
            flex-grow: 1;
        }
    }

    &-read-more {
        padding-top: rem(28);
        padding-bottom: rem(15);

        opacity: 0;

        transition: opacity 200ms ease-in-out;

        @media (--to-medium-screen-w) {
            opacity: 1;
        }
    }
}
