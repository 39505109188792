/*
    Navigation
 */
.navigation {
    @media (--from-navigation-breakpoint) {
        display: flex;
        align-items: center;
    }


    // Elements
    &-item {
        @media (--to-navigation-breakpoint) {
            display: flex;
            flex-wrap: wrap;
        }

        @media (--from-navigation-breakpoint) {
            position: relative;

            display: flex;
            align-items: center;
            margin-right: rem(5);
        }


        // States
        &:hover,
        &.is-active {
            .sub-navigation {
                @media (--from-navigation-breakpoint) {
                    overflow: visible;

                    opacity: 1;
                }
            }
        }

        &:hover .sub-navigation-toggle .icon {
            @media (--from-navigation-breakpoint) {
                transform: rotate(180deg);
            }
        }

        &.is-active {
            @media (--to-navigation-breakpoint) {
                position: relative;

                padding-left: rem(5);

                color: $color-primary;

                &::after {
                    position: absolute;
                    top: rem(18);
                    bottom: rem(18);
                    left: 0;

                    width: rem(5);

                    background-color: currentColor;

                    content: '';
                }
            }


            // Elements
            .sub-navigation {
                @media (--to-navigation-breakpoint) {
                    display: block;
                    max-height: rem(1000);
                }
            }

            .sub-navigation-toggle .icon {
                transform: rotate(180deg);
            }
        }
    }

    &-cta-wrapper {
        @media (--to-navigation-breakpoint) {
            padding-top: rem(30);
            padding-left: rem($horizontal-spacing-small);
        }

        @media (--from-navigation-breakpoint) {
            padding-right: rem(5);
            padding-left: rem(10);
        }
    }

    &-cta {
        @media (--from-navigation-breakpoint) {
            height: rem(38);
            min-width: 0;
            padding-right: rem(20);
            padding-left: rem(20);

            font-size: rem(12);
        }
    }

    &-link {
        display: flex;
        align-items: center;

        margin-bottom: 0;

        color: inherit;
        line-height: 1;
        text-decoration: none;
        white-space: nowrap;

        &:hover {
            color: $color-primary;
        }

        @media (--to-navigation-breakpoint) {
            flex-grow: 1;
            height: rem(50);
            padding-right: rem(20);
            padding-left: rem($horizontal-spacing-small);

            font-size: rem(16);
        }

        @media (--from-navigation-breakpoint) {
            padding-top: rem($horizontal-spacing-smaller);
            padding-right: rem(12);
            padding-bottom: rem($horizontal-spacing-smaller);
            padding-left: rem(12);

            font-size: rem(12);

            border: rem(1) solid transparent;
            border-bottom: 0;
        }


        // States
        &:hover,
        &.is-active {
            & + .sub-navigation-toggle .icon {
                @media (--from-navigation-breakpoint) {
                    color: color($color-light alpha(60%));
                }
            }
        }

        &.is-active {
            color: $color-primary;
        }
    }

    .sub-navigation-toggle {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: rem(24);
        height: rem(40);
        padding: 0;

        color: inherit;
        text-align: right;

        background: transparent;
        border: none;

        @media (--to-navigation-breakpoint) {
            flex-grow: 100;
            justify-content: flex-end;
            height: rem(50);
            padding-right: rem(15);
        }

        @media (--from-navigation-breakpoint) {
            display: none;
        }


        // Elements
        .icon {
            width: rem(34);
            height: rem(34);

            transition: transform 0.2s ease-in-out;
        }
    }
}
