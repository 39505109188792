/*
    Newsletter Content Block
 */
.newsletter-block {
    // Elements
    &-inner {
        position: relative;

        padding-top: rem(25);
        padding-right: rem($horizontal-spacing-small);
        padding-bottom: rem(1);
        padding-left: rem($horizontal-spacing-small);

        color: $color-light;

        background-color: $color-dark;
        background-image: url('../../static/images/newsletter-background.jpg');
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;

        @media (--from-small-screen-w) {
            padding-top: rem(30);
            padding-right: rem($horizontal-spacing-medium);
            padding-left: rem($horizontal-spacing-medium);
        }
        
        @media (--from-medium-screen-w) {
            display: flex;
            align-items: center;
            padding-top: rem(5);
            padding-bottom: rem(5);
        }

        @media (--from-normal-screen-w) {
            padding-top: rem(10);
            padding-right: rem(50);
            padding-bottom: rem(10);
            padding-left: rem(50);
        }


        // Pseudo
        &:after {
            @media (--from-medium-screen-w) {
                position: absolute;
                top: 100%;
                right: 0;

                width: 50%;
                height: rem(10);
                
                background-color: $color-primary;
                content: '';
            }
        }
    }

    &-heading {
        flex-basis: 55%;

        color: inherit;

        @media (--from-medium-screen-w) {
            padding-top: rem(30);
            padding-right: rem(55);
            padding-bottom: rem(35);
            margin-bottom: 0;
        }
    }

    &-intro-and-form {
        @media (--from-medium-screen-w) {
            flex-basis: 45%;
            padding-top: rem(50);
            padding-bottom: rem(10);
        }
    }

    &-intro {
        @media (--from-medium-screen-w) {
            margin-bottom: rem(16);

            font-size: rem(16);
            line-height: 1.5;
        }
    }

    &-form .form-control-alert.danger {
        color: $color-light;
    }
}
