/*
    Preserve Aspect Ratio
*/
.preserve-aspect-ratio {
    position: relative;

    width: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.video::before {
        display: block;
        padding-top: calc(9 / 16 * 100%);
        
        content: '';
    }

    &-media {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        .video {
            width: inherit;
            height: inherit;
        }
    }

    .fixed-image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: $layer-preserve-ratio-fixed-image;

        width: 100%;
        height: auto;

        opacity: 0;
    }
}
