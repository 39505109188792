/*
    Content Section
 */
.content-section {
    margin-top: rem($section-spacing-tiny);
    margin-bottom: rem($section-spacing-tiny);

    @media (--from-small-screen-w) {
        padding-right: rem($horizontal-spacing-medium);
        padding-left: rem($horizontal-spacing-medium);
        margin-top: rem($section-spacing);
        margin-bottom: rem($section-spacing);
    }


    // States
    &:first-child {
        margin-top: 0;
    }


    // Elements
    &-inner.has-aside {
        @media(--from-normal-screen-w) {
            display: flex;
        }


        // Elements
        .content-section-main {
            @media(--from-normal-screen-w) {
                flex-grow: 0;
                flex-shrink: 0;
                width: 50%;
                padding-right: rem(100);
            }
        }
    }

    &-main {
        @media (--to-normal-screen-w) {
            margin-bottom: rem(35);
        }
    }

    &-aside {
        @media(--from-normal-screen-w) {
            flex-grow: 0;
            flex-shrink: 0;
            width: 50%;
            padding-left: rem(100);
        }
    }
}
