/*
    Style Guide
*/
.style-guide {

    // Elements
    &-header {
        padding-top: rem(40);
        margin-bottom: 0;

        @media (--from-normal-screen-w) {
            padding-top: rem(145);
        }

        h1 {
            font-size: rem(35);

            @media (--from-small-screen-w) {
                font-size: rem(60);
            }
        }
    }

    &-section {
        margin-bottom: rem(60);

        @media (--from-normal-screen-w) {
            margin-bottom: rem(90);
        }

        &:first-of-type {
            padding-top: rem(40);

            @media (--from-normal-screen-w) {
                padding-top: rem(100);
            }
        }

        &-header {
            margin-bottom: rem(40);

            @media (--from-normal-screen-w) {
                margin-bottom: rem(90);
            }
        }

        &-title {
            display: flex;

            color: $color-primary;
            font-size: rem(14);
            font-weight: $weight-bold;
            letter-spacing: 0.03em;
            text-transform: uppercase;

            &::after {
                display: block;
                flex-grow: 1;
                height: rem(2);
                margin-top: rem(8);
                margin-left: rem(15);

                background-color: $color-dark;

                content: '';
            }
        }

        &-button-title {
            font-size: rem(16);
            font-weight: $weight-regular;
            letter-spacing: normal;
            text-transform: none;
        }

        .call-to-action {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &-swatches {
        display: flex;
        flex-direction: column;

        @media (--from-small-screen-w) {
            flex-direction: row;
        }


        // Elements
        .swatch {
            width: 100%;
            margin-bottom: rem(20);

            @media (--from-small-screen-w) {
                max-width: rem(155);
            }

            &:not(:last-of-type) {
                @media (--from-small-screen-w) {
                    margin-right: rem(20);
                }
            }
        }
    }

    &-button-group {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: rem(40);


        // Elements
        .button,
        .block-button,
        .text-link {
            margin-right: rem(120);
            margin-bottom: rem(20);
        }

        .button-group button {
            margin: 0;
        }
    }
}