/*
    Notification
 */
.notification {
    position: relative;


    // Types
    &.positive {
        color: $color-light;

        background-color: $color-primary;
    }

    &.neutral {
        background-color: $color-wild-sand;
        border-bottom: rem(1) solid color($color-dark-tint-2 alpha(75%));;
    }

    &.negative {
        color: $color-light;

        background-color: $color-dark;
    }


    // States
    &:not(.is-visible) {
        display: none;
    }


    // Elements
    &-inner {
        display: flex;
        align-items: center;

        padding-top: rem(15);
        padding-bottom: rem(15);
    }

    &-content {
        flex-basis: 100%;

        @media(--to-small-screen-w) {
            padding-right: rem(20);
        }

        @media(--from-small-screen-w) {
            padding-left: rem(25);
        }


        // Elements
        &-title {
            font-family: $font-primary;
            font-weight: $weight-bold;
        }
    }

    &-icon {
        display: none;

        @media(--from-small-screen-w) {
            display: block;
        }
    }

    p {
        margin-bottom: 0;

        font-size: rem(14);
        line-height: 1.4;

        @media (--from-medium-screen-w) {
            font-size: rem(15);
        }
    }

    a {
        &,
        &:not(.button):hover {
            color: inherit
        }
    }
}
