/*
    Newsletter Signup
 */
.newsletter-signup {
    margin-bottom: rem(10);

    .newsletter-signup-form {
        &.no-border {
            .input {
                border: none;
            }
        }

        &.inline {
            .input {
                @media (--from-medium-screen-w) {
                    width: 230px;
                    margin-right: 10px;
                }
            }
        }
    }

    .form-fields {
        display: flex;
        flex-wrap: wrap;
    }
    
    // Elements
    .input,
    .button {
        height: rem(48);
    }

    .input {
        font-size: rem(14);

        border: rem(3) solid $color-dark;

        margin-bottom: 5px;

        background-color: $color-light;

        @media (--from-medium-screen-w) {
            font-size: rem(16);
        }

        // Pseudo
        &::placeholder {
            color: $color-dark;
        }
    }

    .button {
        flex-shrink: 0;
        min-width: 0;
        padding-right: rem(10);
        padding-left: rem(10);
        margin-right: rem(10);

        font-size: rem(16);
        text-transform: none;
    }

    &-message {
        margin-bottom: rem(10);

        color: $color-dark;
        text-align: left;
    }
}
