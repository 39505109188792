/*
    Pagebreak Quote
*/
.pagebreak-quote {
    max-width: rem($constrain-width);
    padding-top: rem(20);
    margin-right: auto;
    margin-left: auto;


    // Elements
    &-inner {
        position: relative;

        @media (--from-medium-screen-w) {
            padding-right: rem($horizontal-spacing-medium);
        }
    }

    &-content {
        padding-right: rem($horizontal-spacing-small);
        padding-left: rem($horizontal-spacing-small);
        margin-bottom: rem(20);
        
        text-align: left;
        
        outline: none;

        @media (--from-small-screen-w) {
            padding-right: rem($horizontal-spacing-medium);
            padding-left: rem($horizontal-spacing-medium);
        }

        @media (--from-large-screen-w) {
            padding-right: rem($horizontal-spacing);
            padding-left: rem($horizontal-spacing);
        }


        // Elements
        &-text {
            position: relative;

            &::before {
                position: absolute;
                top: 0;
                left: rem(-$horizontal-spacing-small);

                width: rem(4);
                height: 100%;

                background-color: $color-primary;

                content: '';

                @media (--from-small-screen-w) {
                    left: rem(-$horizontal-spacing-medium);
                }

                @media (--from-large-screen-w) {
                    left: rem(-$horizontal-spacing);
                }
            }
        }

        &-icon {
            width: rem(11);
            height: rem(11);
            margin-right: rem(7);

            @media (--from-normal-screen-w) {
                width: rem(17);
                height: rem(17);
            }
        }

    }

    &-pagination {
        @media (--from-small-screen-w) {
            display: none;
        }
    }

    &-button-group {
        position: absolute;
        right: rem($horizontal-spacing-medium);
        bottom: rem(0);

        @media (--to-small-screen-w) {
            display: none;
        }
    }
}