/*
    Heading
*/
h1,
h2,
h3,
h4,
h5,
.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-5 {
    margin-top: 0;
    margin-bottom: rem(20);

    color: $color-dark;

    font-family: $font-primary;
    font-weight: $weight-bold;
    line-height: 1;
}


// Headings
h1,
.heading-1 {
    font-size: rem(35);
    line-height: 1.13;

    @media (--from-small-screen-w) {
        font-size: rem(50);
        line-height: 1.45;
    }
}

h2,
.heading-2 {
    position: relative;

    font-size: rem(25);
    line-height: 1.28;

    @media (--from-small-screen-w) {
        font-size: rem(35);
        line-height: 1.26;

        letter-spacing: 0.02em;
    }


    // Types
    &.primary {
        &:before {
            position: absolute;
            top: 50%;
            left: rem(-20);

            width: rem(4);
            height: 100%;

            background-color: $color-primary;

            content: '';

            transform: translateY(-50%);

            @media (--from-small-screen-w) {
                left: rem(-$horizontal-spacing-medium);
            }

            @media (--from-large-screen-w) {
                left: rem(-40);
            }
        }
    }
}

h3,
.heading-3 {
    font-size: rem(16);
    line-height: 1.31;

    @media (--from-small-screen-w) {
        font-size: rem(20);
        line-height: 1.4;
    }
}

h4,
.heading-4 {
    font-size: rem(16);
    line-height: 1.12;
    text-transform: uppercase;

    @media (--from-small-screen-w) {
        font-size: rem(16);
        line-height: 1.56;
        letter-spacing: 0.08em;
    }
}

h5,
.heading-5 {
    font-size: rem(14);
    line-height: 1.78;
    letter-spacing: 0.07em;
    text-transform: uppercase;

    @media (--from-small-screen-w) {
        font-size: rem(14);
    }
}
