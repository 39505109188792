/*
    Image Carousel
*/
$--image-desktop-height: 500;
$--image-tablet-height: 350;
$--image-mobile-height: 250;
$--image-margin-bottom: 35;
$--pagination-button-height: 50;
 
.image-carousel {
    @media(--to-small-screen-w) {
        padding-right: 0;
        padding-left: 0;
    }

    @media (--from-small-screen-w) {
        padding-right: rem($horizontal-spacing-medium);
        padding-left: rem($horizontal-spacing-medium);
    }
    
    
    // Elements
    &-inner {
        position: relative;

        @media (--to-small-screen-w) {
            padding: 0;
        }
    }

    &-item {
        display: flex;
        flex-direction: column;
        padding: 0 rem(5);

        @media (--from-small-screen-w) {
            padding: 0;
        }

        // IE 11 fix: Adding padding to avoid showing next item's blue border
        @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
            padding: 0 rem(1);
        }

        outline: none;

    }

    .slick-slide.slick-active .image-carousel-content { 
        @media (--to-small-screen-w) {
            padding-top: rem(0);
       
            opacity: 1;
        }
    }

    &-content {    
        @media (--to-small-screen-w) {
            padding-top: rem(40);

            opacity: 0;

            transition: opacity 0.15s ease-in-out, padding 0.25s ease-in-out;
        }

        @media (--from-small-screen-w) {
            padding: 0 rem($horizontal-spacing-medium);
        }

        @media (--from-large-screen-w) {
            padding: 0 rem($horizontal-spacing);
        }


        // Elements
        .title,
        .subtitle {
            @media (--from-medium-screen-w) {
                width: rem(660);
            }
        }

        .title {
            @media (--from-small-screen-w) {
                width: calc(100% - rem(180));
            }
        }
    }

    &-media {
        height: rem($--image-mobile-height);
        margin-bottom: rem(45);
        
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        
        @media (--from-small-screen-w) {
            height: rem($--image-tablet-height);
            margin-bottom: rem($--image-margin-bottom);
        }

        @media (--from-medium-screen-w) {
            height: rem($--image-desktop-height)
        }
    }

    &-img {
        height: 0;
    }

    &-pagination {
        position: absolute;
        top: calc(rem($--image-mobile-height) + rem(15));
        right: 0;
        
        width: rem(165);
        margin-right: rem($horizontal-spacing-small);
        margin-left: rem($horizontal-spacing-small);

        @media (--from-small-screen-w) {
            top: calc(rem($--image-tablet-height) + rem($--image-margin-bottom));
        }

        @media (--from-small-screen-w) and (--to-medium-screen-w) {
            margin-right: rem($horizontal-spacing-medium);
            margin-left: rem($horizontal-spacing-medium);
        }

        @media (--from-medium-screen-w) {
            top: calc(rem($--image-desktop-height) + rem($--image-margin-bottom));

            margin-bottom: rem(40);
            margin-left: calc(rem($horizontal-spacing-medium) + rem($horizontal-spacing-medium));
        }
    }

    &-button-group {
        @media (--to-small-screen-w) {
            display: none;
        }
    }

    &-screen-reader-text {
        position: absolute;

        display: block;
        width: 0;
        height: 0;
        overflow: hidden;
    }

    .carousel-control {
        top: calc(rem($--image-mobile-height) / 2 - rem($--pagination-button-height) / 2);

        @media (--from-small-screen-w) {
            top: calc(rem($--image-tablet-height) / 2 - rem($--pagination-button-height) / 2);
        }

        @media (--from-medium-screen-w) {
            top: calc(rem($--image-desktop-height) / 2 - rem($--pagination-button-height) / 2);
        }
    }
}