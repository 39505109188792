/*
    Swatch
*/
.swatch {

    // Types
    &.outline .swatch-inner {
        color: $color-dark;

        border: rem(1) solid $color-dark;


        // Elements
        .swatch-title::after {
            color: $color-dark;
        }
    }


    // Colours
    &.colour-primary .swatch-inner {
        background-color: $color-primary;


        // Elements
        .swatch-title::after {
            content: '$color-primary';
        }
    }

    &.colour-dark .swatch-inner {
        background-color: $color-dark;


        // Elements
        .swatch-title::after {
            content: '$color-dark';
        }

        .tint-1 {
            background-color: $color-dark-tint-1;
        }

        .tint-2 {
            background-color: $color-dark-tint-2;
        }
    }

    &.colour-light .swatch-inner {
        background-color: $color-light;


        // Elements
        .swatch-title::after {
            content: '$color-light';
        }

        .shade-1 {
            background-color: $color-light-shade-1;
        }
    }


    // Elements
    &-inner {
        position: relative;

        height: rem(70);

        color: $color-light;

        border-radius: rem(3);

        @media (--from-small-screen-w) {
            height: rem(110);
        }
    }

    &-title {
        margin-bottom: 0;

        font-size: rem(14);
        font-weight: $weight-semi-bold;
    }

    &-title::after {
        font-size: rem(14);
        font-weight: $weight-light
    }

    &-title {
        padding: rem(15) rem(5) rem(5) rem(14);

        @media (--from-small-screen-w) {
            padding-top: rem(40);
        }
    }

    &-title,
    &-variation {

        &::after {
            display: block;

            text-transform: uppercase;

            opacity: 0.8;
        }
    }

    &-variations {
        position: absolute;
        bottom: 0;
        left: 0;

        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    &-variation {
        flex-basis: 50%;
        height: rem(14);
    }
}
