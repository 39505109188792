/*
    Form:
      - Textarea
      - Form label
      - Form field set
      - Form field
      - Form validation
*/
// Textarea
textarea {
    display: block;
    max-height: rem(400);
    min-height: rem(100);
    padding-top: rem(7); // Aligns textarea text in the same position as a text input field

    line-height: inherit;
}


// Form label
.form-label {
    display: block;
    margin-bottom: rem(10);

    color: $color-dark;
    font-family: $font-primary;
    font-size: rem(16);
    font-weight: $weight-bold;

    @media (--from-small-screen-w) {
        margin-bottom: rem(10);

        font-size: rem(18);
    }
}


// Form group
.form-group {
    display: block;


    // Types
    &:first-of-type {
        padding-top: rem(10);
    }
}


// Form control
.form-control {
    width: 100%;
    max-width: 100%;
    min-height: rem(46);
    padding-left: rem(14);

    color: $color-dark;
    font-family: $font-primary;
    font-size: rem(18);

    background-color: transparent;
    border: rem(1) solid $color-border;
    border-radius: rem(4);

    transition: border linear 250ms, color linear 250ms, caret-color linear 250ms;
    appearance: none;
    caret-color: $color-primary;


    // States
    &:focus,
    &:focus-within {
        border-color: $color-primary;
        outline: 0;
    }

    &.is-invalid {
        color: $color-danger;

        border-color: $color-danger;

        caret-color: $color-danger;
    }


    // Elements
    &::placeholder {
        color: $color-placeholder;
    }
}


// Form control alert
.form-control-alert {
    display: block;
    min-height: rem(30);
    padding-top: rem(8);

    font-family: $font-primary;
    font-size: rem(12);
    font-weight: $weight-bold;
    letter-spacing: 0.07em;
    text-align: right;
    text-transform: uppercase;


    // Types
    &.danger {
        color: $color-danger;
    }
}


// Input group
.input-group {
    display: flex;


    // Types
    &.no-border {
        .form-control {
            border: 0;
        }

        .button.input-group-append {
            margin-left: 0;
        }
    }


    // Elements
    .form-control {
        border: rem(3) solid $color-dark;


        // Types
        &:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

    }

    .button.input-group-append {
        margin-left: rem(-3);
        
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
