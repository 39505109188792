/*
    Quote
*/
blockquote,
q {
    margin: 0;
    
    quotes: '“' '”' '‘' '’';

    @media (--from-medium-screen-w) {
        text-align: center;
    }


    // Elements
    p {
        color: $color-dark;
        font-family: $font-primary;
        font-size: rem(25);
        font-weight: $weight-bold;
        line-height: 1.28;

        @media (--from-normal-screen-w) {
            margin-bottom: rem(50);
            
            font-size: rem(35);
        }
    }

    p:first-child {

        // Elements
        &::before {
            content: open-quote;
        }

        &::after {
            content: close-quote;
        }
    }

    em {
        color: $color-dark;
        font-family: $font-primary;
        font-size: rem(14);
        font-style: normal;
        font-weight: $weight-bold;
        line-height: 1.78;
        letter-spacing: 0.07em;

        text-transform: uppercase;

        a {
            color: $color-primary; 
        }
    }
}
