/*
    List
    - Ordered
    - Unordered
    - List item
*/
ul, ol {
    padding: 0;
    padding-left: rem(25);
    margin: 0;
    margin-bottom: rem(30);

    list-style: none;

    @media (--from-small-screen-w) {
        padding-left: rem(50);
        margin-bottom: rem(80);
    }
}

li {
    margin-bottom: rem(18);
}

ul li {
    position: relative;

    &:not(.icon) {
        &:before {
            position: absolute;
            top: rem(7);
            left: rem(-20);
    
            width: rem(7);
            height: rem(7);
            
            background-color: $color-primary;
            border-radius: rem(10);
    
            content: '';
    
            @media (--from-small-screen-w) {
                top: rem(6);
                left: rem(-25);
    
                width: rem(9);
                height: rem(9);
            }
        }
    }

    .icon {
        position: absolute;
        top: rem(-2);
        left: rem(-30);

        width: rem(25);  
        height: rem(25);

        @media (--from-small-screen-w) {
            top: rem(-5);
            left: rem(-33);
    
            width: rem(30);  
            height: rem(30);
        }
    }
}

ol {
    counter-reset: ordered-list;

    li {
        position: relative;

        &:before {
            position: absolute;
            top: rem(3);
            left: rem(-25);

            width: rem(16);
            height: rem(16);

            color: $color-light;
            font-family: $font-primary;
            font-size: rem(10);
            font-weight: $weight-bold;
            text-align: center;

            background-color: $color-dark;
            border-radius: rem(10);
            
            content: counter(ordered-list);
            counter-increment: ordered-list;

            @media (--from-small-screen-w) {
                top: rem(2);
            }
        }
    }
}