/*
    Dropdown
*/
.dropdown {
    position: relative;

    padding-top: rem(8);
    padding-right: rem(50);
    padding-bottom: rem(8);


    // Elements
    .icon {
        position: absolute;
        top: 50%;
        right: rem(25);

        width: rem(18);
        height: rem(18);

        transform: translate(50%, -50%);
    }

    &-select {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: block;
        width: 100%;
        height: 100%;

        font-size: inherit;
        
        opacity: 0;
    }
}
