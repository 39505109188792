/*
    Tile Block
 */
.tile-block {
    // Elements
    &-title {
        margin-bottom: rem(40);

        @media (--from-small-screen-w) {
            margin-bottom: rem(60);
        }
    }

    &-grid-wrapper {
        display: none;

        @media (--from-medium-screen-w) {
            position: relative;
            display: flex;
            align-items: stretch;
            flex-wrap: wrap;
            padding-bottom: rem(36);
            padding-right: calc(rem($horizontal-spacing-medium) - rem(10));
            padding-left: calc(rem($horizontal-spacing-medium) - rem(10));
        }

        .tile {
            flex-basis: calc(100% / 3);
            margin-bottom: rem(30);
            height: unset;
        }

        .button.view-more {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &-carousel-wrapper {
        @media (--from-smaller-screen-w) {
            padding-right: calc(rem($horizontal-spacing-small) - rem(5));
            padding-left: calc(rem($horizontal-spacing-small) - rem(5));
        }

        @media (--from-small-screen-w) {
            padding-right: calc(rem($horizontal-spacing-medium) - rem(5));
            padding-left: calc(rem($horizontal-spacing-medium) - rem(5));
        }

        @media (--from-medium-screen-w) {
            display: none;
        }
    }

    &-pagination {
        padding-top: rem(20);
        padding-bottom: rem(20);

        @media (--from-medium-screen-w) {
            display: none;
        }
    }

    .slick-track {
        @media (--from-smaller-screen-w) {
            display: flex;
        }
    }

    .slick-slide {
        @media (--from-smaller-screen-w) {
            float: none;
            height: auto;


            // Elements
            > div {
                height: 100%;
            }
        }
    }
}
