/*
    Accordion
 */
.accordion {
    @media (--to-medium-screen-w) {
        padding-top: rem($section-spacing-small);
        padding-bottom: rem($section-spacing-small);
    }


    // Elements
    &-inner {
        @media (--from-medium-screen-w) {
            display: flex;
            align-items: flex-start;
        }
    }

    &-heading-wrapper {
        @media (--from-small-screen-w) {
            padding-left: rem($horizontal-spacing-medium);
        }

        @media (--from-medium-screen-w) {
            flex-basis: rem(340);
            flex-grow: 0;
            flex-shrink: 0;
        }

        @media (--from-large-screen-w) {
            padding-left: rem($horizontal-spacing);
        }
    }

    &-items {
        @media (--from-medium-screen-w) {
            flex-grow: 1;
        }
    }

    &-item {
        //States
        &.is-active {
            background-color: $color-light;


            // Elements
            .icon {
                transform: rotate(180deg);
            }

            .accordion-item-content {
                display: block;

            }
        }


        // Types
        &:last-child {
            margin-bottom: rem(0);
        }


        // Elements
        &-header {
            position: relative;

            width: 100%;
            padding-top: rem(10);
            padding-right: rem(10);
            padding-bottom: rem(10);
            padding-left: 0;
            overflow: hidden;

            font-size: rem(16);
            font-weight: $weight-bold;
            text-align: left;

            background-color: transparent;
            border: 0;

            appearance: none;

            @media (--from-medium-screen-w) {
                padding-bottom: rem(20);
            }


            // Elements
            .icon {
                position: absolute;
                top: rem(4);
                left: rem(-8);

                width: rem(30);
                height: rem(30);

                transition: 0.15s transform ease-in-out;

                @media (--from-small-screen-w) {
                    width: rem(40);
                    height: rem(40);
                }
            }
        }

        &-title {
            padding-left: rem(20);
            margin-bottom: 0;

            white-space: normal;

            @media (--from-small-screen-w) {
                padding-left: rem(45);
            }
        }

        &-content {
            display: none;
            padding-right: rem(10);
            padding-bottom: rem(30);
            padding-left: rem(20);

            @media (--from-small-screen-w) {
                padding-left: rem(45);
            }
        }
    }
}
