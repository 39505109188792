/*
    Search Form
 */
.search-form {
    display: flex;
    align-items: flex-end;

    border-bottom: rem(2) solid currentColor;

    &-input {
        flex-grow: 1;
        height: rem(40);
        padding-top: 0;
        padding-right: 0;
        padding-bottom: 0;
        padding-left: rem(15);

        font-family: inherit;
        font-size: rem(16);
        font-weight: $weight-bold;

        background-color: transparent;
        border: 0;

        caret-color: $color-primary;

        @media (--from-medium-screen-w) {
            height: rem(75);
            padding-left: rem(40);

            font-size: rem(35);
        }


        // States
        &:focus {
            outline: 0;
        }


        // Elements
        &::placeholder {
            color: $color-dark-tint-1;
        }
    }

    &-submit {
        width: rem(40);
        height: rem(40);
        min-width: 0;
        padding: 0;

        border: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        @media (--from-medium-screen-w) {
            width: auto;
            height: rem(50);
            padding-right: rem(40);
            padding-left: rem(25);
        }


        // Elements
        .icon {
            width: rem(38);
            height: rem(38);
        }

        &-label {
            display: none;

            @media (--from-medium-screen-w) {
                display: inline-block;
            }
        }
    }

    &-load-more {
        margin-top: rem(15);
    }
}
