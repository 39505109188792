/*
    Pagination Indication
 */
.pagination-indication {
    display: flex;
    align-items: center;

    width: 100%;


    // States
    &.fixed-width {
        @media (--from-small-screen-w) {
            max-width: rem(165);
        }
    }


    // Elements
    &-number {
        margin-bottom: 0;

        color: inherit;
    }

    &-line {
        position: relative;

        flex-grow: 1;
        height: rem(6);
        margin-right: rem(12);
        margin-bottom: rem(3);
        margin-left: rem(12);

        border-bottom: rem(2) solid currentColor;

        @media (--from-small-screen-w) {
            height: rem(4);

            margin-right: rem(8);
            margin-bottom: rem(1);
            margin-left: rem(8);
        }
    }

    &-line-jut {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;

        background-color: currentColor;

        transition: width ease-in-out 200ms;
    }
}
