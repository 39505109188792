/*
    Newsletter Signup and Social Profile Links
 */
.newsletter-signup-and-social-profile-links {
    padding-top: rem(25);
    padding-bottom: rem(40);

    border-top: rem(1) solid $color-border;

    @media (--from-medium-screen-w) {
        padding-bottom: rem(25);
    }

    // Elements
    &-inner {
        @media (--from-medium-screen-w) {
            display: flex;
            justify-content: space-between;
        }
    }

    .newsletter-signup-wrapper {
        flex-basis: 70%;

        .privacy-policy {
            font-size: rem(14);

            @media (--from-medium-screen-w) {
                font-size: rem(16);
                margin-bottom: 0;
            }
        }
    }

    .social-profile-links-wrapper {
        flex-shrink: 0;
    }
}
