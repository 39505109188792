/*
    Carousel Control
 */
.carousel-control {
    position: absolute;
    top: calc(50% - rem(25));

    width: rem(50);
    height: rem(50);

    color: $color-light;

    cursor: pointer;
    background-color: color($color-dark alpha(55%));
    border: 0;
    outline: 0;


    // States
    &:hover,
    &:focus {
        background-color: color($color-dark alpha(70%));
    }

    &:active {
        background-color: color($color-dark alpha(75%));
    }

    &.previous {
        left: rem(15);

        transform: rotate(90deg);
    }

    &.next {
        right: rem(15);

        transform: rotate(-90deg);
    }


    // Elements
    .icon {
        position: absolute;
        top: 50%;
        left: 50%;

        width: rem(35);
        height: rem(35);

        transform: translate(-50%, -45%);
    }
}