/*
    Animation
 */

// Fade Up
@keyframes animate-fade-left {
    0% {
        opacity: 0;

        transform: translateX(rem(10));
    },
    100% {
        opacity: 1;

        transform: translateX(0);
    }
}

.animate-fade-left {
    animation-duration: 150ms;
    animation-name: animate-fade-left;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}
