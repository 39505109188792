/*
    Strip
*/
.strip {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: $color-light;
    z-index: $layer-strip;
    transition: transform 0.35s ease-in-out;
    transform: translateY(100%);
    box-shadow: 0 -4px 4px 0 $color-placeholder;

    &.is-active {
        transform: translateY(0);
    }

    .inner {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 rem(20);
        height: rem(50);

        @media (--from-medium-screen-w) {
            height: rem(80);
        }

        @media (--from-xlarge-screen-w) {
            transform: translateX(30px);
            padding: 0;
        }
    }

    .logo {
        display: block;
        height: rem(40);
        max-width: 100%;
        max-height: 100%;
        margin-right: rem(30);

        @media (--from-medium-screen-w) {
            height: rem(60);
        }
        
    }

    .icon-close {
        width: rem(30);
        height: rem(30);
        color: $color-gray;
    }
}