/*
    Homepage Hero Carousel
 */
.homepage-hero-carousel {
    position: relative;

    line-height: 0;

    // Elements
    &-item {
        position: relative;

        height: calc(100vh - rem(200));
        min-height: rem(200);

        background-color: $color-dark;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        @media (--to-medium-screen-w) {
            max-height: rem(550);
        }

        @media (--to-small-screen-w) {
            max-height: rem(450);
        }


        // States
        &:after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            width: 100%;
            height: 100%;

            background-image: linear-gradient(185deg, color($color-dark alpha(0%)) 30%, $color-dark 100%);
            content: '';
        }
    }

    &-video {
        position: absolute;
        top: 50%;
        left: 0;

        width: 100%;

        transform: translateY(-50%);

        &::before {
            display: block;
            padding-top: calc(9 / 16 * 100%);

            content: '';
        }

        &-outer,
        &-inner {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            width: 100%;
            height: 100%;

            background: $color-dark;
        }
    }

    // Title nested inside of slides for screen readers
    .screen-reader-only-text {
        position: absolute;

        display: block;
        width: 0;
        height: 0;
        overflow: hidden;
    }

    .carousel-control {
        @media (--to-medium-screen-w) {
            display: none;
        }
    }
}
