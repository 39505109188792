/*
    Not Found
*/
.not-found {
    margin-top: rem(80);
    margin-bottom: rem(120);


    // Elements
    .image-gallery-single-content {
        @media (--from-normal-screen-w) {
            width: rem(340);
        }
    }

    .image-gallery-single-media {
        @media (--from-normal-screen-w) {
            margin-left: rem(240);
        }
    }
}