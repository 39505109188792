/*
    Icon Button
 */
.icon-button {
    padding: 0;
    margin: 0;

    color: inherit;
    font-family: inherit;

    background-color: transparent;
    border: none;

    cursor: pointer;
}
