/*
    Contact us form
 */
.contact-us-form {
    max-width: rem(415);


    // Elements
    .g-recaptcha {
        margin-top: rem(10);
    }

    &-actions {
        padding-top: rem(20);
    }
}
