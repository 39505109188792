/*
    Homepage Sub Hero
 */
.homepage-sub-hero {
    position: relative;

    margin-bottom: rem($section-spacing-tiny);

    @media (--from-small-screen-w) {
        margin-bottom: rem($section-spacing);
    }

    // Elements
    &-slider-and-pagination {
        color: $color-light;
    }

    &-slider {
        position: absolute;
        right: 0;
        bottom: calc(100% + rem(30));
        left: 0;
        
        @media (--from-medium-screen-w) {
            right: rem(225);
            bottom: 100%;
        }

        // Elements
        .slick-list {
            overflow: visible;
        }
    }

    &-slider-slide {
        // Elements
        &-inner {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;

            padding-top: rem($horizontal-spacing-small);
            padding-right: rem($horizontal-spacing-small);
            padding-bottom: rem($horizontal-spacing-small);
            padding-left: rem($horizontal-spacing-small);

            @media (--from-medium-screen-w) {
                padding-top: rem(30);
                padding-right: rem(125);
                padding-bottom: rem(15);
                padding-left: rem(30);
            }
        }

        &-kia-ora {
            margin-bottom: rem(12);

            color: $color-primary;

            @media (--from-medium-screen-w) {
                margin-bottom: rem(3);

                font-size: rem(16);
            }
        }

        &-title {
            margin-bottom: rem(10);

            color: inherit;
            font-size: rem(25);

            @media (--from-medium-screen-w) {
                font-size: rem(35);
            }
        }
    }

    &-pagination {
        position: absolute;
        bottom: 100%;
        left: 0;

        width: rem(195);
        padding-right: rem($horizontal-spacing-small);
        padding-bottom: rem(18);
        padding-left: rem($horizontal-spacing-small);

        @media (--from-medium-screen-w) {
            right: 0;
            left: auto;

            width: rem(225);
            padding-top: rem(25);
            padding-right: rem(30);
            padding-bottom: rem(26);
            padding-left: rem(20);
        }
    }

    &-opening-hours {
        padding-top: rem(20);
        padding-right: rem($horizontal-spacing-small);
        padding-bottom: rem(20);
        padding-left: rem($horizontal-spacing-small);

        background-color: $color-light-shade-1;

        @media (--to-medium-screen-w) {
            font-size: rem(12);
        }

        @media (--from-medium-screen-w) {
            padding-top: rem(18);
            padding-right: rem(40);
            padding-bottom: rem(18);
            padding-left: rem(40);
            margin-top: rem(-30);
        }
    }
}
