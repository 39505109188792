/*
    Search Dialog
 */
.search-dialog {
    position: fixed;
    top: rem($header-height);
    left: 0;
    z-index: $layer-search-dialog;

    width: 100%;
    height: calc(100vh - rem($header-height));
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    background-color: $color-light;
    opacity: 0;

    transform: translateX(-100%);

    @media (--from-navigation-breakpoint) {
        top: rem($header-height-large);

        height: calc(100vh - rem($header-height-large));
    }


    // States
    &.is-active {
        opacity: 1;

        transform: translateX(0);
    }


    // Elements
    &-inner {
        padding-top: rem(54);
        padding-bottom: rem(54);

        @media (--from-medium-screen-w) {
            padding-top: rem(75);
        }

        @media (--from-normal-screen-w) {
            padding-top: rem(160);
            padding-bottom: rem(160);
        }
    }
}
