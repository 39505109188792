/*
    Navigation Bar
 */
.navigation-bar {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;


    // States
    &.is-active &-inner {
        @media (--to-navigation-breakpoint) {
            opacity: 1;

            transform: translateX(0);
        }
    }

    &.is-hidden {
        display: none;
    }


    // Elements
    &-inner {
        @media (--to-navigation-breakpoint) {
            position: fixed;
            top: rem($header-height);
            left: 0;

            width: 100%;
            height: calc(100vh - rem($header-height));
            padding-top: rem(40);
            padding-bottom: rem(20);
            overflow-y: scroll;

            background-color: $color-light;

            opacity: 0;

            transform: translateX(-100%);
        }

        @media (--from-navigation-breakpoint) {
            display: flex;
            align-items: center;
        }
    }
}
