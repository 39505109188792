/*
    Footer
*/
.footer {
    position: relative;

    background-color: $color-light-shade-1;
    background-position: center;
    background-size: cover;
    
    // Elements
    &-container {
        background-color: rgba(32, 53, 86, 0.9);
    }

    &-inner {
        display: flex;
        flex-direction: column;
        padding-top: rem(60);
        padding-bottom: rem(20);

        @media (--from-small-screen-w) {
            flex-direction: row;
        }
    }

    &-section {
        flex-basis: 100%;      

        @media (--from-small-screen-w) {
            flex-basis: 35%;
            margin-bottom: 0;
        }


        // Elements
        &:not(:last-of-type) {
            margin-bottom: rem(15);

            @media (--from-small-screen-w) {
                margin-right: rem(60);
            }

            @media (--from-normal-screen-w) {
                margin-right: rem(75);
            }
        }

        &-title {
            color: $color-light-shade-1;
            font-size: rem(14);
            font-weight: $weight-bold;
            letter-spacing: 0.07em;
        }

        &-item {
            margin-bottom: rem(30);
            
            color: $color-light-shade-1;
            font-size: rem(16);

            &.phone {
                margin-bottom: 0;
            }

            &.email {
                white-space: nowrap;
            }
        }
        
        .link {
            border-bottom: none;

            @media (--from-normal-screen-w) {
                border-bottom: rem(1) solid transparent;

                transition: color 0.18s ease-in-out, border-color 0.18s ease-in-out;
                            
                &:hover {
                    border-color: $color-light-shade-1
                }
            }
        }
    }

    &-navigation {
        @media (--from-normal-screen-w) {
            flex-basis: 30%;
        }

        @media (--from-large-screen-w) {
            flex-basis: rem(368);
            flex-grow: 1;
            flex-shrink: 0;
        }
        

        // Elements
        nav {
            column-count: 2;
            column-gap: 0;

            @media (--from-small-screen-w) and (--to-large-screen-w) {
                column-count: 1;
            }
        }

        .link {
            display: table;
            padding-bottom: rem(2);
            margin-bottom: rem(30);
            
            color: $color-light-shade-1;

            @media (--to-small-screen-w) {
                display: inline-block;
                width: 100%;
            }
        }
    }

    &-copyright {
        border-top: 1px solid $color-footer-border;
        padding-bottom: rem(20);

        // Elements
        &-inner {
            display: flex;
            flex-direction: column;
            padding-top: rem(30);
            padding-bottom: rem(30);
            
            text-align: center;
            
            @media (--from-small-screen-w) {
                flex-direction: row;
                justify-content: space-between;
                padding-top: rem(40);
                
                text-align: left;
            }

            @media (--from-normal-screen-w) {
                align-items: flex-end;
            }
        }

        .link {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-right: rem(10);
            margin-bottom: rem(30);
            
            color: $color-light-shade-1;
            font-size: rem(12);

            @media (--from-normal-screen-w) {
                flex-direction: row;
                margin-bottom: 0;
            }

            & > * {
                position: relative;
                display: inline-block;
                margin-bottom: 10px;
                line-height: 1;

                @media (--from-normal-screen-w) {
                    margin-right: 5px;
                    margin-bottom: 0;
                }
            }

            .link-spacer {
                display: none;

                @media (--from-normal-screen-w) {
                    display: inline-block;
                }
            }
        }

        .logo {
            width: rem(220);
            height: auto;
        }
    }
}