/*
    Sub Navigation
 */
$--dropdown-width: 200;

.sub-navigation {
    @media (--to-navigation-breakpoint) {
        display: none;
        flex-basis: 100%;
        padding-left: rem($horizontal-spacing-small);
    }

    @media (--from-navigation-breakpoint) {
        position: absolute;
        top: 0;
        left: 0;
        z-index: $layer-navigation;

        width: 0;
        height: 0;
        overflow: hidden;

        opacity: 0;

        transition:
            opacity 0.1s ease-in-out;
    }


    // Elements
    &-items {
        @media (--from-navigation-breakpoint) {
            width: rem($--dropdown-width);
            margin-top: rem(-1);

            background-color: $color-light;

            border: rem(1) solid color($color-dark-tint-2 alpha(50%));
        }
    }

    &-item {
        display: flex;
        align-items: center;
        height: rem(45);
        margin-bottom: 0;

        color: inherit;
        font-size: rem(16);
        line-height: 1;
        text-decoration: none;

        @media (--from-navigation-breakpoint) {
            display: block;
            height: auto;
            padding-top: rem($horizontal-spacing-smaller);
            padding-right: rem(12);
            padding-bottom: rem($horizontal-spacing-smaller);
            padding-left: rem(12);

            color: $color-primary;
            font-size: rem(12);

            background-color: transparent;

            &:not(:first-of-type) {
                padding-top: rem(10);
            }
        }


        // States
        &:hover,
        &.is-active {
            @media (--from-navigation-breakpoint) {
                color: $color-primary;
            }
        }
    }

    &-item-overlay {
        @media (--to-navigation-breakpoint) {
            display: none;
        }

        @media (--from-navigation-breakpoint) {
            position: relative;

            display: inline-block;

            white-space: nowrap;

            background-color: $color-light;
            border: rem(1) solid color($color-dark-tint-2 alpha(50%));
            border-bottom: 0;
        }
    }
}
