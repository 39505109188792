/*
    Social Profile Links
 */
.social-profile-links {

    @media (--to-medium-screen-w) {
        text-align: center;
    }

    @media (--from-medium-screen-w) {
        padding-top: rem(42);
    }


    // Elements
    .social-profile-link {
        display: inline-block;
        width: rem(40);
        height: rem(40);
        min-width: 0;
        padding: 0;
        margin-right: rem(25);

        border-radius: 50%;

        transition: color 0.25s ease-in-out;


        // Types
        &:last-child {
            margin-right: 0;
        }


        // States
        &:hover {
            color: $color-primary;
        }
    }
}
