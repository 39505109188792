/*
    Banner
 */
.banner {
    // Elements
    .heading-group {
        padding-top: rem(30);
        padding-bottom: rem(35);

        @media (--from-medium-screen-w) {
            padding-top: rem(50);
            padding-bottom: rem(55);
        }
    }

    .parent-name {
        min-height: rem(24);
        margin-bottom: rem(15);

        color: $color-primary;

        @media (--from-medium-screen-w) {
            margin-bottom: rem(25);

            font-size: rem(16);
        }
    }

    .title {
        border-bottom: rem(2) solid currentColor;

        @media (--from-medium-screen-w) {
            border-bottom: rem(3) solid currentColor;
        }
    }

    .title-text {
        display: inline-block;

        padding-bottom: rem(12);

        border-bottom: rem(5) solid currentColor;

        @media (--from-medium-screen-w) {
            padding-bottom: rem(25);

            border-bottom: rem(6) solid currentColor;
        }
    }
}
