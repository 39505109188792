/*
    Image Gallery Single
*/
.image-gallery-single {
    padding-top: rem(50);
    padding-bottom: rem(50);

    overflow: hidden;

    @media (--from-small-screen-w) {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    @media (--from-normal-screen-w) {
        flex-direction: row;
        height: rem(650);
    }

    &-content {
        width: 100%;

        @media (--to-normal-screen-w) {
            margin-bottom: rem(40);
        }

        @media (--from-normal-screen-w) {
            flex-shrink: 0;
            width: rem(340);
        }
    }

    &-media {
        position: relative;

        width: calc(100% - rem(14));
        height: rem(380);

        @media (--from-small-screen-w) {
            width: 100%;
            height: rem(500);
            max-width: rem(400);
        }

        @media (--from-normal-screen-w) {
            margin-left: rem(230);
        }

        &::before {
            position: absolute;
            top: 0;
            right: rem(-14);

            width: rem(14);
            height: 55%;

            background-color: $color-primary;

            @media(--from-small-screen-w) {
                top: 0;
                right: rem(-34);
                z-index: $layer-image-gallery-tiles;

                width: rem(34);
                height: 55%;
            }

            content: '';
        }

        &::after {
            position: absolute;
            bottom: rem(-22);
            left: rem(-20);
            z-index: $layer-image-gallery-tiles;

            width: calc(100% + rem(54));
            height: 60%;

            background-color: $color-light-shade-1;

            @media (--from-small-screen-w) {
                bottom: rem(-70);
                left: rem(-140);

                width: rem(800);
                height: rem(300);
            }

            content: '';
        }
    }
}
