/*
    RTE Content
 */
.rte-content {

    // Elements
    figure {
        margin: 0;
    }

    img {
        display: block;
        max-width: 100%;
        margin-bottom: rem(20);
    }

    a.button {
        margin-bottom: rem(7);
    }

    > *:last-child {
        margin-bottom: 0;
    }
}
