/*
    Event Details Aside
*/
.event-details-aside {
    &-row {
        display: flex;
        margin-bottom: rem(5);

        &-icon {
            flex-grow: 0;
            flex-shrink: 0;
            width: rem(40);
            height: rem(40);
            margin-right: rem(5);
        }

        &-text {
            flex-grow: 1;
            padding-top: rem(9);
            margin-bottom: 0;

            @media (--from-medium-screen-w) {
                padding-top: rem(6);
            }
        }
    }

    &-book-now {
        margin-top: rem(20);
    }
}
