/*
    Whats On Tile
*/
.whats-on-tile {
    width: 100%;
    
    @media (--from-small-screen-w) {
        padding: 0 rem($horizontal-spacing-smaller);
    }

    @media (--from-tablet-breakpoint) {
        flex-basis: 50%;
        max-width: 50%;
        margin-bottom: rem(35);
    }

    &:not(:last-child) {
        margin-bottom: rem(30);
    }


    // Elements
    &-inner {
        display: flex;
    }

    &-image {
        flex-grow: 1;
        flex-shrink: 0;
        width: rem(110);
        height: rem(110);
        background-size: cover;
        background-position: center;
        
        background-color: $color-dark;

        @media (--from-tiny-screen-w) {
            width: rem(125);
            height: rem(125);
        }

        @media (--from-small-screen-w) {
            width: rem(150);
            height: rem(150);
        }

        @media (--from-normal-screen-w) {
            width: rem(205);
            height: rem(205);
        }
    }

    &-content {   
        position: relative;

        width: 100%;
        height: rem(110);
        
        border: 1px solid $color-dark-tint-2;
        border-left: none;

        @media (--from-tiny-screen-w) {
            height: rem(125);
        }

        @media (--from-small-screen-w) {
            height: rem(150);
        }
        
        @media (--from-normal-screen-w) {
            height: rem(205);
        }


        // Elements
        > *  {
            width: 100%;
            padding-right: rem(18);
            padding-left: rem(10);

            @media (--from-small-screen-w) {
                padding-right: rem(30);
                padding-left: rem(30);
            }

            @media (--from-tablet-breakpoint) {
                padding-right: rem(12);
                padding-left: rem(15);
            }

            @media (--from-normal-screen-w) {
                padding-right: rem(25);
                padding-left: rem(30);
            }
        }
    }

    &-title {
        position: absolute;
        top: rem(30);
        left: 0;
        
        margin: 0;
        
        font-size: rem(12);
        
        @media (--from-tiny-screen-w) and (--to-normal-screen-w) {
            top: rem(40);

            font-size: rem(16);
        }

        @media (--from-normal-screen-w) {
            top: rem(70);
            
            font-size: rem(20);
        }
    }

    &-category {
        position: absolute;
        top: rem(5);
        left: 0;

        color: rgba(0, 0, 0, 0.4);
        font-family: $font-primary;
        font-size: rem(10);
        font-weight: $weight-bold;
        line-height: 2;
        letter-spacing: 0.06em;
        text-transform: uppercase;

        @media (--from-tiny-screen-w) {
            top: rem(7);
            
            font-size: rem(14);
        }

        @media (--from-small-screen-w) {
            top: rem(15);
            left: 0;
            
            font-size: rem(10);
        }

        @media (--from-tablet-breakpoint) {
            top: rem(15);
            left: 0;
        }

        @media (--from-normal-screen-w) {
            top: rem(30);
            left: 0;

            font-size: rem(12);
        }
    }

    &-details {
        position: absolute;
        bottom: rem(5);
        left: 0;

        display: flex;
        justify-content: space-between;

        @media (--from-small-screen-w) {
            bottom: rem(12);
        }

        @media (--from-normal-screen-w) {
            bottom: rem(20);
        }


        // Elements
        .price, .date {
            font-size: rem(10);

            @media (--from-tiny-screen-w) {
                font-size: rem(12);
            }

            @media (--from-normal-screen-w) {
                font-size: rem(14);
            }
        }

        .price {
            text-transform: uppercase;
        }
    }
}