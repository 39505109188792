/*
    Text Link
*/
button.text-link {
    background-color: transparent;
    border: none;

    appearance: none;
}

.text-link {
    display: inline-flex;
    align-items: center;
    height: rem(30);

    color: $color-primary;
    font-family: $font-primary;
    font-size: rem(14);
    font-weight: $weight-bold;
    letter-spacing: 0.08em;
    text-transform: uppercase;


    // Elements
    .icon {
        position: relative;
        top: 0;
        
        width: rem(25);
        margin-left: rem(8);
    }
}
