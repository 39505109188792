/*
    Text Content Block
*/
.constrain-width.text-content-block:not(.no-pad) {
    @media (--from-small-screen-w) {
        padding-left: calc(2 * rem($horizontal-spacing-medium));
    }

    @media (--from-large-screen-w) {
        padding-left: calc(rem($horizontal-spacing-medium) + rem(40));
    }
}

.text-content-block {
    p {
        // ckeditor will apply float to aligned images
        // this makes them overflow and move elements outside the content block
        // use clearfix to prevent this
        overflow: auto;
    }
}
