/*
    Button Group
*/
.button-group {
    display: flex;

    
    // Elements
    .button {
        display: flex;
        width: rem(48);
        height: rem(50);
        min-width: 0;
        padding: 0;
    
        border-radius: 0;
    
    
        // Types
        &.previous {
            transform: rotate(180deg);
        }
    
    
        // Elements
        svg {
            width: rem(25);
            height: rem(25);
        }
    }
}