/*
    Loader
*/
.loader {
    width: rem(30);
    height: rem(6);

    background-color: $color-primary;

    animation: load 1.8s ease-in-out infinite;


    // Pseudo
    &:before,
    &:after {
        position: absolute;

        display: block;
        height: rem(6);

        background-color: $color-dark;
        content: '';

        animation: load 1.8s ease-in-out infinite;
    }

    &:before {
        top: rem(-12);
        left: rem(6);

        width: rem(24);
    }

    &:after {
        bottom: rem(-12);

        width: rem(21);
    }
}

@keyframes load {
    0% {
        transform: translateX(rem(24));
    }
    50% {
        transform: translateX(rem(-18));
    }
    100% {
        transform: translateX(rem(24));
    }
}

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(200);

    @media (--from-medium-screen-w) {
        height: rem(300);
    }
}

.loader-text {
    display: none;
}
