/*
    Call To Action
*/
.call-to-action {
    padding-right: 0;
    padding-left: 0;

    @media (--from-normal-screen-w) {
        padding-right: rem($horizontal-spacing-medium);
        padding-left: calc(2 * rem($horizontal-spacing-medium));
    }

    @media (--from-large-screen-w) {
        padding-left: calc(rem($horizontal-spacing-medium) + rem(40));
    }
    

    // Elements
    &-inner {
        display: flex;
        justify-content: space-between;
        
        @media (--to-normal-screen-w) {
            flex-direction: column;
        }

        @media (--from-normal-screen-w) {
            position: relative;

            min-height: rem(600);
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: rem(40);
        
        @media (--to-small-screen-w) {
            padding-right: rem($horizontal-spacing-small);
            padding-left: rem($horizontal-spacing-small);
        }

        @media (--from-small-screen-w) and (--to-normal-screen-w) {
            padding-right: rem($horizontal-spacing-medium);
            padding-left: calc(2 * rem($horizontal-spacing-medium));
        }
        
        @media (--to-normal-screen-w) {
            order: 1;
        }
        
        @media (--from-normal-screen-w) {
            flex-grow: 1;
            width: 100%;
            max-width: rem(370);
            padding-top: 0;
            margin-right: rem(80);
        }

        .button {
            margin-top: rem(10);

            @media (--from-normal-screen-w) {
                margin-top: rem(15);
            }
        }
    }

    &-media {
        width: 100%;
        height: rem(300);
        
        @media (--from-small-screen-w) {
            height: rem(430);
        }
        
        @media (--to-normal-screen-w) {
            order: 0
        }
        
        @media (--from-normal-screen-w) {
            height: rem(550);
            max-width: rem(550);
            margin-top: rem(25);
        }

        &::before {   
            @media (--from-normal-screen-w) {
                position: absolute;
                top: rem(-15);
                right: 0;
                z-index: $layer-call-to-action-tiles;
                
                width: 52%;
                height: rem(15);
                
                background-color: $color-primary;
                content: '';
            }
        }
    }
}
