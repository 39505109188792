/* 
    Video
*/
.video-content-block {
    position: relative;
    
    margin-bottom: rem(80);

    @media (--from-normal-screen-w) {
        display: flex;
        margin-bottom: rem(140);
    }


    // Elements
    &::before {
        position: absolute;
        bottom: 0;
        left: 0;

        width: 100%;
        height: rem(40);

        content: '';

        @media (--from-normal-screen-w) {
            height: rem(60);
        }
    }

    &-content {
        padding-right: rem($horizontal-spacing-small);

        @media (--from-small-screen-w) {
            padding-right: rem($horizontal-spacing-medium);
            padding-left: rem($horizontal-spacing-medium);
        }

        @media (--to-normal-screen-w) {
            margin-bottom: rem(40);
        }
        
        @media (--from-normal-screen-w) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: rem(500);
            padding-right: 0;
            margin-right: rem(60);
        }

        @media (--from-large-screen-w) {
            padding-left: rem($horizontal-spacing);
        }
    }

    &-inner {
        &::after {
            position: absolute;
            top: rem(55);
            left: rem(36);
            z-index: $layer-video-tile;
            
            width: 100vw;
            height: 95%;
            
            background-color: $color-light-shade-1;

            content: '';

            @media (--to-tiny-screen-w) {
                height: 85%;
            }

            @media (--from-medium-screen-w) {
                top: rem(65);
                left: rem(62);

                height: 100%;
            }

            @media (--from-normal-screen-w) {
                top: rem(80);
                left: rem(78);
            }
        }

        .video {
            width: 100%;
            height: 100%;

            background: $color-dark;
            border: none;
        }
    }
}