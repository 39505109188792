/*
    Search Results
        - Search results group
        - Search result totals
 */
.search-results-group {

    // Elements
    &-item {
        position: relative;

        display: block;
        padding-top: rem(30);
        padding-bottom: rem(30);
        padding-left: rem(15);

        border-bottom: rem(1) solid $color-border;

        @media (--from-medium-screen-w) {
            padding-top: rem(50);
            padding-bottom: rem(50);
            padding-left: rem(40);


            // Elements
            &::after {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;

                display: block;
                width: rem(4);

                background-color: $color-primary;
                border-radius: rem(1000);
                content: '';
                opacity: 0;

                transition: opacity ease-in-out 200ms;
            }
        }


        // States
        &:hover {
            @media (--from-medium-screen-w) {
                &::after {
                    opacity: 1;
                }
            }

            .search-results-group-item-heading {
                color: $color-primary;
            }
        }

    }

    &-item-heading {
        margin-bottom: rem(15);

        transition: color ease-in-out 200ms;
    }
}

.search-results-found {
    padding-top: rem(20);
    padding-left: rem(15);
    margin-bottom: 0;

    color: $color-primary;
    font-size: rem(12);

    @media (--from-medium-screen-w) {
        padding-top: rem(30);
        padding-bottom: rem(20);
        padding-left: rem(40);
        margin-bottom: rem(20);
    }
}

.search-results-not-found {
    padding-top: rem(20);
    padding-bottom: rem(20);
    padding-left: rem(15);

    @media (--from-medium-screen-w) {
        padding-top: rem(30);
        padding-left: rem(40);
    }
}
