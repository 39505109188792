/*
    Simple Dropdown
*/
.simple-dropdown {
    position: relative;
    
    display: inline-block;

    padding-right: 1.2em;

    color: $color-primary;


    // Elements
    .icon {
        position: absolute;
        top: 57%;
        right: 0.5em;

        width: 0.65em;
        height: 0.65em;

        transform: translate(50%, -50%);
    }

    &-select {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: block;
        width: 100%;
        height: 100%;

        font-size: inherit;
        
        opacity: 0;
    }
}
