/*
    File Download
*/
.file-download {

    // Elements
    &-inner {
        display: flex;

        border: rem(1) solid $color-dark;
        border-radius: rem(2);
    }

    &-details {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        padding-top: rem(12);
        padding-right: rem(12);
        padding-bottom: rem(10);
        padding-left: rem(12);

        @media(--from-small-screen-w) {
            align-items: center;
            flex-direction: row;

            padding-top: rem(24);
            padding-right: rem(18);
            padding-bottom: rem(24);
            padding-left: rem(40);
        }
    }

    &-title {
        margin-bottom: rem(4);

        font-size: rem(16);
        font-weight: $weight-bold;
        line-height: 1.2;

        @media(--from-small-screen-w) {
            flex-grow: 1;

            margin-right: rem(15);
            margin-bottom: 0;

            font-size: rem(20);
        }
    }

    &-info {
        font-size: rem(12);
        line-height: 1.2;
        white-space: nowrap;

        @media(--from-small-screen-w) {
            font-size: rem(18);
        }
    }

    &-link {
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        width: rem(53);
        height: auto;
        min-width: 0;
        padding: 0;

        border-radius: 0;

        @media(--from-small-screen-w) {
            width: rem(75);
        }


        // Elements
        .icon {
            width: rem(24);
            height: rem(24);

            transform: rotate(90deg);
        }
    }
}
