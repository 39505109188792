/*
    Page
*/
*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    position: relative;

    min-height: 100%;
}

body {
    width: 100%;
    padding: 0;
    margin: 0;

    // Types
    &.is-fixed {
        position: fixed;
    }
}

.base {
    overflow: hidden;
}
