/*
    Whats On
*/
.whats-on {
    position: relative;

    
    // Elements
    &-header {
        margin-bottom: rem(15);

        @media (--to-small-screen-w) {
            font-size: rem(16);
        }

        @media (--from-small-screen-w) {
            margin-bottom: rem(30);
        }
        
        @media (--from-tablet-breakpoint) {
            margin-bottom: rem(60);
        }
    }

    &-inner {
        padding: 0 rem($horizontal-spacing-small);

        @media (--from-small-screen-w) {
            padding: 0 rem($horizontal-spacing-smaller);
        }

        @media (--from-tablet-breakpoint) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &-page-link {
        margin-top: rem(15);

        @media (--from-tablet-breakpoint) {
            position: absolute;
            top: rem(30);
            right: 0;
            
            margin: 0;
        }
        
        
        // Elements
        .link {
            color: $color-primary;
    
            @media (--to-small-screen-w) {
                font-size: rem(12);
            }
        }
    }

    .view-more {
        display: flex;
        justify-content: center;

        margin-top: rem(15);
        @media (--from-tablet-breakpoint) {
            margin-top: 0;
        }
    }
}